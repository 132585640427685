<!-- 图片裁剪 -->
<template>
  <a-modal
    title="上传图片"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :width="400"
    :destroyOnClose="true"
    :footer="null"
    @cancel="cancelHandel">
    <a-row>
      <p>注：若图片无法上传，请检查图片大小，图片控制在1M以内</p>
      <a-col :xs="24" :md="24" :style="{height: '350px'}">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :info="true"
          :autoCrop="options.autoCrop"
          :autoCropWidth="cutImgWidth"
          :autoCropHeight="cutImgHeight"
          :fixedBox="options.fixedBox"
          :fixedNumber="fixedNumber"
          :fixed="true"
        >
        </vue-cropper>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col :lg="6" :md="6">
        <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false">
          <a-button icon="upload">选择图片</a-button>
        </a-upload>
      </a-col>
      <a-col :lg="{span: 2, offset: 2}" :md="2">
        <a-button icon="plus" @click="changeScale(1)"/>
      </a-col>
      <a-col :lg="{span: 2, offset: 1}" :md="2">
        <a-button icon="minus" @click="changeScale(-1)"/>
      </a-col>
      <a-col :lg="{span: 2, offset: 1}" :md="2">
        <a-button icon="undo" @click="rotateLeft"/>
      </a-col>
      <a-col :lg="{span: 2, offset: 1}" :md="2">
        <a-button icon="redo" @click="rotateRight"/>
      </a-col>
      <a-col :lg="{span: 2, offset: 1}" :md="2">
        <a-button type="primary" @click="finish('blob')">保存</a-button>
      </a-col>
    </a-row>
  </a-modal>

</template>
<script>
import { VueCropper } from 'vue-cropper'
import config from '@/http/config'
export default {
  components: {
    VueCropper
  },
  props: {
    cutImgWidth: {
      type: Number,
      default: 147
    },
    cutImgHeight: {
      type: Number,
      default: 206
    },
    fixedNumber: {
      type: Array,
      default:()=> [1,1]
    }
  },
  data () {
    return {
      visible: false,
      id: null,
      confirmLoading: false,
      uploading: false,
      imgurl: config.baseImgURL,
      options: {
        img: '',
        autoCrop: true,
        fixedBox: false
      }
    }
  },
  methods: {
    edit (id) {
      this.visible = true
      this.id = id
      /* 获取原始头像 */
    },
    close () {
      this.id = null
      this.visible = false
      this.options.img = ''
    },
    cancelHandel () {
      this.close()
    },
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    beforeUpload (file) {
      const reader = new FileReader()
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      return false
    },

    // 上传图片（点击上传按钮）
    finish (type) {
      const _this = this
      const formData = new FormData()
      // 输出
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          const img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
          formData.append('file', data, Math.floor(Math.random() * 1000000000000) + '.jpg')
          this.$api.sys.fileUpload(formData, { contentType: false, processData: false, headers: { 'Content-Type': 'application/x-www-form-urlencoded'} })
            .then((response) => {
              if (response.status === 0) {
                _this.$message.success('上传成功')
                  this.visible = false
                  _this.options.img = response.data.qiniuUrl
                  _this.$emit('ok', response.data.qiniuUrl)
                  _this.cancelHandel()
              } else {
                _this.$message.err('上传失败')
              }
            })
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.model = true
          this.modelSrc = data
        })
      }
    }
  }
}
</script>

<style scoped>
  .avatar-upload-preview {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;
    overflow: hidden;
  }
  .avatar-upload-preview img {
    width: 100%;
    height: 100%;
  }
</style>
